import { __assign, __rest, __spreadArrays } from "tslib";
import { get, merge } from 'lodash';
import classNames from 'classnames';
import { createToolbarTheme } from './utils';
import { LINK_AND_HASHTAG_COLOR, POST_HEADER_THREE_COLOR, POST_HEADER_THREE_FONT, POST_HEADER_TWO_COLOR, POST_HEADER_TWO_FONT, POST_PAGE_FONT, POST_TEXT_COLOR, POST_QUOTES_FONT, POST_TITLE_COLOR, POST_TITLE_FONT, POST_QUOTES_COLOR, IS_POST_ADVANCED_DESIGN_OPTIONS_ENABLED_PATH, POST_HEADER_FOUR_FONT, POST_HEADER_FOUR_COLOR, POST_HEADER_FIVE_COLOR, POST_HEADER_SIX_COLOR, POST_HEADER_SIX_FONT, POST_HEADER_FIVE_FONT, } from '../../constants/wix-params';
import { getStyleColor, getPresetColor } from '../../services/colors';
import { extractFontObjAttrs } from '../../services/fonts';
import { getStyleObject } from './get-style';
import stylesOOI from './themeRicos-ooi.scss';
import stylesIframe from './themeRicos.scss';
import stylesBM from './business-manager-theme.scss';
var presetsFallbacks = {
    h1: 'Title',
    h2: 'Page-title',
    h3: 'Heading-XL',
    h4: 'Heading-L',
    h5: 'Heading-M',
    h6: 'Heading-S',
    quote: 'Body-M',
    p: 'Body-M',
};
var postPresets = {
    h1: [POST_TITLE_FONT, POST_TITLE_COLOR],
    h2: [POST_HEADER_TWO_FONT, POST_HEADER_TWO_COLOR],
    h3: [POST_HEADER_THREE_FONT, POST_HEADER_THREE_COLOR],
    h4: [POST_HEADER_FOUR_FONT, POST_HEADER_FOUR_COLOR],
    h5: [POST_HEADER_FIVE_FONT, POST_HEADER_FIVE_COLOR],
    h6: [POST_HEADER_SIX_FONT, POST_HEADER_SIX_COLOR],
    link: [POST_PAGE_FONT, LINK_AND_HASHTAG_COLOR],
    hashtag: [POST_PAGE_FONT, LINK_AND_HASHTAG_COLOR],
    quote: [POST_QUOTES_FONT, POST_QUOTES_COLOR],
    p: [POST_PAGE_FONT, POST_TEXT_COLOR],
};
var fullPostPresets = function (section) {
    var fullPostFont = function (font) { return "post-full-" + section + "-" + font + "Font"; };
    var fullPostColor = function (color) { return "post-" + section + "-" + color + "Color"; };
    return __assign(__assign({}, postPresets), { h1: [fullPostFont('title'), fullPostColor('title')], p: [fullPostFont('description'), fullPostColor('description')], link: [fullPostFont('description'), fullPostColor('linkHashtag')], hashtag: [fullPostFont('description'), fullPostColor('linkHashtag')], quote: [fullPostFont('description'), fullPostColor('description')] });
};
var layoutToWixParams = function (_a) {
    var appSettings = _a.appSettings, isMobile = _a.isMobile, section = _a.section, isPostPage = _a.isPostPage, isBusinessManager = _a.isBusinessManager, isEditor = _a.isEditor, shouldApplyPostDesignInFeed = _a.shouldApplyPostDesignInFeed, rest = __rest(_a, ["appSettings", "isMobile", "section", "isPostPage", "isBusinessManager", "isEditor", "shouldApplyPostDesignInFeed"]);
    var mobileFontSize = rest.mobileFontSize, mobileHeaderThreeFontSize = rest.mobileHeaderThreeFontSize, mobileHeaderTwoFontSize = rest.mobileHeaderTwoFontSize, mobileHeaderFourFontSize = rest.mobileHeaderFourFontSize, mobileHeaderFiveFontSize = rest.mobileHeaderFiveFontSize, mobileHeaderSixFontSize = rest.mobileHeaderSixFontSize, mobileQuotesFontSize = rest.mobileQuotesFontSize, headerTwoFontSize = rest.headerTwoFontSize, headerThreeFontSize = rest.headerThreeFontSize, headerFourFontSize = rest.headerFourFontSize, headerFiveFontSize = rest.headerFiveFontSize, headerSixFontSize = rest.headerSixFontSize;
    var postOrBiz = isPostPage || isBusinessManager || shouldApplyPostDesignInFeed;
    var advancedPostOpts = get(appSettings, IS_POST_ADVANCED_DESIGN_OPTIONS_ENABLED_PATH, false);
    var useBasicFontSize = !postOrBiz || !advancedPostOpts;
    var fontSizePresets = {
        h2: isMobile
            ? mobileHeaderTwoFontSize
            : useBasicFontSize
                ? headerTwoFontSize
                : undefined,
        h3: isMobile
            ? mobileHeaderThreeFontSize
            : useBasicFontSize
                ? headerThreeFontSize
                : undefined,
        h4: isMobile
            ? mobileHeaderFourFontSize
            : useBasicFontSize
                ? headerFourFontSize
                : undefined,
        h5: isMobile
            ? mobileHeaderFiveFontSize
            : useBasicFontSize
                ? headerFiveFontSize
                : undefined,
        h6: isMobile
            ? mobileHeaderSixFontSize
            : useBasicFontSize
                ? headerSixFontSize
                : undefined,
        quote: isMobile ? mobileQuotesFontSize : postOrBiz ? '24px' : undefined,
        p: isMobile ? mobileFontSize : postOrBiz ? '18px' : undefined,
    };
    var getLineHeightPresets = function (isFallback) { return ({
        p: isFallback && 'normal',
        h2: isFallback && isMobile && '30px',
        h3: isFallback && isMobile && '26px',
        h4: isFallback && isMobile && '20px',
        h5: isFallback && isMobile && '18px',
        h6: isFallback && isMobile && '16px',
    }); };
    /** Sets values depended on Blog's predefined layouts */
    var getLayoutStyle = function (element) {
        if (isPostPage) {
            switch (element) {
                case 'link':
                case 'hashtag':
                case 'p':
                    return {
                        // taken from app.scss -> .blog-post-page-font | .blog-post-description-font
                        fontStyle: 'inherit',
                        fontWeight: 'inherit',
                        textDecoration: 'inherit',
                    };
                case 'h2':
                case 'h3':
                case 'h4':
                case 'h5':
                case 'h6':
                    if (advancedPostOpts) {
                        var color = postPresets[element][1];
                        return { color: getStyleColor(appSettings, color) };
                    }
                    break;
                default:
                    break;
            }
        }
        else {
            if (!isBusinessManager && !shouldApplyPostDesignInFeed) {
                if (element === 'quote') {
                    // taken from theme-ooi.scss -> .advancedQuoteStylesOff
                    return { fontSize: '24px' };
                }
            }
            else if (element === 'quote') {
                return { lineHeight: 'normal' };
            }
        }
        return {};
    };
    var presets = postOrBiz ? postPresets : fullPostPresets(section);
    var elementMapper = function (idx) {
        return Object.entries(presets).reduce(function (prev, curr) {
            var _a;
            return (__assign(__assign({}, prev), (_a = {}, _a[curr[0]] = curr[1][idx], _a)));
        }, {});
    };
    var fontPresets = elementMapper(0);
    var colorPresets = elementMapper(1);
    return {
        fontPresets: fontPresets,
        colorPresets: colorPresets,
        fontSizePresets: fontSizePresets,
        getLineHeightPresets: getLineHeightPresets,
        getLayoutStyle: getLayoutStyle,
    };
};
var correctPx = function (value) {
    return typeof value === 'string' ? value.replace(/px/g, '') + "px" : value + "px";
};
export function createRicosTheme(_a) {
    var appSettings = _a.appSettings, isMobile = _a.isMobile, isPostPageEnabled = _a.isPostPageEnabled, isBusinessManager = _a.isBusinessManager, section = _a.section, isPostPage = _a.isPostPage, parentClass = _a.parentClass, isEditor = _a.isEditor, shouldApplyPostDesignInFeed = _a.shouldApplyPostDesignInFeed, isRicosStylingSkinEnabled = _a.isRicosStylingSkinEnabled;
    var _b = getStyleObject(appSettings, {
        isPostPageEnabled: isPostPageEnabled,
        isBusinessManager: isBusinessManager,
    }), textColor = _b.textColor, linkHashtagColor = _b.linkHashtagColor, actionColor = _b.highlightedColor, bgColor = _b.opaqueBackgroundColor, rest = __rest(_b, ["textColor", "linkHashtagColor", "highlightedColor", "opaqueBackgroundColor"]);
    var _c = layoutToWixParams(__assign({ appSettings: appSettings,
        isMobile: isMobile,
        section: section,
        isPostPage: isPostPage,
        isBusinessManager: isBusinessManager,
        isEditor: isEditor,
        shouldApplyPostDesignInFeed: shouldApplyPostDesignInFeed }, rest)), fontPresets = _c.fontPresets, fontSizePresets = _c.fontSizePresets, getLineHeightPresets = _c.getLineHeightPresets, colorPresets = _c.colorPresets, getLayoutStyle = _c.getLayoutStyle;
    var getColor = function (element, presetFallback) {
        var isHeading = /^h[2-6]$/.test(element);
        var isLinkHashtag = element === 'link' || element === 'hashtag';
        var color = getStyleColor(appSettings, colorPresets[element]) ||
            (presetFallback && getPresetColor(appSettings, presetFallback)) ||
            (isHeading && '#212121') ||
            (isLinkHashtag && linkHashtagColor);
        return { color: color };
    };
    /** Font Object from Blog settings */
    var getCustomFontObj = function (element) {
        return get(appSettings, "style.fonts." + fontPresets[element]);
    };
    var getStyleByRicosElement = function (element) {
        var additionals = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            additionals[_i - 1] = arguments[_i];
        }
        var fallback = false;
        var fontObj = getCustomFontObj(element);
        if (!fontObj) {
            // Fetches fallback from Platform Presets
            fontObj = get(appSettings, "textPresets." + presetsFallbacks[element]);
            fallback = true;
        }
        var isBoolean = function (val) { return typeof val === 'boolean'; };
        var isLinkOrHashtag = element === 'link' || element === 'hashtag';
        var style = merge.apply(void 0, __spreadArrays([fontObj
                ? __assign(__assign({}, extractFontObjAttrs(fontObj)), { textDecoration: fontObj.style && isBoolean(fontObj.style.underline)
                        ? fontObj.style.underline
                            ? 'underline'
                            : undefined
                        : fontObj.decoration, fontSize: !isLinkOrHashtag && correctPx(fontObj.size), lineHeight: undefined }) : {}, fallback ? { fontSize: fontSizePresets[element] } : {}], additionals));
        if (isLinkOrHashtag) {
            style.fontFamily = undefined;
        }
        return style;
    };
    var bmColor = '#3899ec';
    var fallbackColor = isBusinessManager ? bmColor : undefined;
    var palette = {
        bgColor: bgColor,
        textColor: textColor,
        actionColor: actionColor,
        fallbackColor: fallbackColor,
    };
    var paletteConfig = {
        settingsActionColor: fallbackColor,
        focusActionColor: fallbackColor,
    };
    var blogFontSize = function (element) {
        var fontSize = fontSizePresets[element];
        if (!fontSize) {
            return {};
        }
        return {
            fontSize: typeof fontSize === 'string' ? correctPx(fontSize) : fontSize + 'px',
        };
    };
    var blogLineHeight = function (element) {
        var lineHeight = getLineHeightPresets(!getCustomFontObj(element))[element];
        if (!lineHeight) {
            return {};
        }
        return { lineHeight: lineHeight };
    };
    var customStyles = {
        h1: getStyleByRicosElement('h1', getColor('h1', 'color-5'), getLayoutStyle('h1')),
        h2: getStyleByRicosElement('h2', getColor('h2', 'color-5'), blogFontSize('h2'), blogLineHeight('h2'), getLayoutStyle('h2')),
        h3: getStyleByRicosElement('h3', getColor('h3', 'color-5'), blogFontSize('h3'), blogLineHeight('h3'), getLayoutStyle('h3')),
        h4: getStyleByRicosElement('h4', getColor('h4', 'color-5'), blogFontSize('h4'), blogLineHeight('h4'), getLayoutStyle('h4')),
        h5: getStyleByRicosElement('h5', getColor('h5', 'color-5'), blogFontSize('h5'), blogLineHeight('h5'), getLayoutStyle('h5')),
        h6: getStyleByRicosElement('h6', getColor('h6', 'color-5'), blogFontSize('h6'), blogLineHeight('h6'), getLayoutStyle('h6')),
        link: getStyleByRicosElement('link', getColor('link'), getLayoutStyle('link')),
        hashtag: getStyleByRicosElement('hashtag', getColor('hashtag'), getLayoutStyle('hashtag')),
        quote: getStyleByRicosElement('quote', getColor('quote', 'color-5'), { borderColor: linkHashtagColor }, // linkHashtagColor
        isMobile ? blogFontSize('quote') : {}, blogLineHeight('quote'), getLayoutStyle('quote')),
        p: getStyleByRicosElement('p', getColor('p', 'color-5'), isMobile ? blogFontSize('p') : {}, getLayoutStyle('p')),
    };
    return __assign(__assign({}, (isRicosStylingSkinEnabled ? {} : { palette: palette, paletteConfig: paletteConfig })), { customStyles: customStyles,
        parentClass: parentClass });
}
export function getCssOverride(_a) {
    var _b;
    var _c = _a === void 0 ? {} : _a, isViewer = _c.isViewer, version = _c.version, isOOI = _c.isOOI, isCompact = _c.isCompact, isReadOnly = _c.readOnly, isBusinessManager = _c.isBusinessManager, appSettings = _c.appSettings;
    var styles = isOOI ? stylesOOI : stylesIframe;
    var dividerContainer = styles.dividerContainer, wrapper = styles.wrapper, readOnly = styles.readOnly, sizeCompact = styles.sizeCompact, text = styles.text, orderedListItem = styles.orderedListItem, orderedListContainer = styles.orderedListContainer, unorderedListItem = styles.unorderedListItem, unorderedListContainer = styles.unorderedListContainer, toolbarButtonWrapper = styles.toolbarButtonWrapper, toolbarButton = styles.toolbarButton, footerToolbar = styles.footerToolbar, dividerBorderColor = styles.dividerBorderColor, headerTwo = styles.headerTwo, headerTwoFontSize = styles.headerTwoFontSize, headerThree = styles.headerThree, headerThreeFontSize = styles.headerThreeFontSize, left = styles.left, center = styles.center, right = styles.right, justify = styles.justify, rest = __rest(styles, ["dividerContainer", "wrapper", "readOnly", "sizeCompact", "text", "orderedListItem", "orderedListContainer", "unorderedListItem", "unorderedListContainer", "toolbarButtonWrapper", "toolbarButton", "footerToolbar", "dividerBorderColor", "headerTwo", "headerTwoFontSize", "headerThree", "headerThreeFontSize", "left", "center", "right", "justify"]);
    var defaultToolbarTheme = {
        toolbarButton_wrapper: toolbarButtonWrapper,
        toolbarButton: toolbarButton,
    };
    var advancedPostOpts = get(appSettings, IS_POST_ADVANCED_DESIGN_OPTIONS_ENABLED_PATH, false);
    var headerTwoStyles = !advancedPostOpts &&
        classNames('blog-post-title-font', headerTwo, headerTwoFontSize);
    var headerThreeStyles = !advancedPostOpts &&
        classNames('blog-post-title-font', headerThree, headerThreeFontSize);
    var theme = __assign(__assign(__assign({}, rest), createToolbarTheme('footer', __assign(__assign({}, defaultToolbarTheme), { toolbar: classNames(footerToolbar, dividerBorderColor) }))), { headerTwo: headerTwoStyles, headerThreeStyles: headerThreeStyles, wrapper: classNames(wrapper, (_b = {},
            _b[readOnly] = isReadOnly,
            _b[sizeCompact] = isCompact,
            _b)), readOnly: readOnly,
        sizeCompact: sizeCompact, 'divider-container': isViewer && dividerContainer, text: text, left: isViewer && classNames(left, text), center: isViewer && classNames(center, text), right: isViewer && classNames(right, text), justify: isViewer && classNames(justify, text), orderedList: classNames(text, /7.16.2/.test(version) && isViewer ? orderedListItem : undefined), orderedListContainer: orderedListContainer, unorderedList: classNames(text, unorderedListItem), unorderedListContainer: unorderedListContainer });
    if (isBusinessManager) {
        // copied from business-manager-theme.js
        var input = stylesBM.input, label = stylesBM.label, textarea = stylesBM.textarea, textInputInputInvalid = stylesBM.textInputInputInvalid, imageSettingsSection = stylesBM.imageSettingsSection, galleryImageSettingsSection = stylesBM.galleryImageSettingsSection, imageSettingsLabel = stylesBM.imageSettingsLabel;
        return __assign(__assign({}, theme), { textInput_input: input, textInput_input_invalid: textInputInputInvalid, inputWithLabel_input: input, inputWithLabel_textArea: textarea, inputWithLabel_label: label, imageSettingsSection: imageSettingsSection, imageSettings_section: galleryImageSettingsSection, galleryImageSettings_section: galleryImageSettingsSection, layoutsSelector_label: label, layoutsSelector_tile_label: label, radioGroupHorizontal_title: label, sliderWithInput_label: label, imageRatioSelector_label: label, imageRatioSelector_ratioLabel: label, thumbnailPlacementSelector_label: label, imageSettingsLabel: imageSettingsLabel });
    }
    return theme;
}
